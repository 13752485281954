import React from "react";
import { useState } from "react"; 
import axios from "axios";
import {useHistory} from "react-router-dom";


export default function Content() {
  const history = useHistory();
  




  const [nombre,setNombre] = useState("");
  const [tema,setTema] = useState("");
  const [dia,setDia] = useState("");
  const [inputs,setInputs] = useState({});
  

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}));
    setInputs(values => ({...values, ['codigo']: 'form12'}));
  }

  const handleSubmit = (event) => {
    event.preventDefault();
//http://localhost:80/chileinmu/api/user/save
//https://appc.codecland.com/api/user/save
    axios.post('http://localhost:80/chileinmu/api/user/save', inputs).then(function(response){
      console.log(response.data);
      history.push('/Ver');
  });

    //console.log(inputs);
  }


  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>General Form</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="#">Home</a>
                </li>
                <li className="breadcrumb-item active">General Form</li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">Quick Example</h3>
                </div>

                <form onSubmit={handleSubmit}>
                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="nombre">Nombre</label>
                      <a href="ver">aquí para ver</a>
                      <input
                        type="text"
                        className="form-control"
                        id="nombre"
                        name="nombre"
                        onChange={handleChange}
                        placeholder="ingrese Nombre"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">Tema</label>
                      <input
                        type="text"
                        className="form-control"
                        id="tema"
                        name="tema"
                        onChange={handleChange}
                        placeholder="Tema"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="dia">Dia</label>
                      <div className="input-group">
                        <input
                        type="date" 
                        className="form-control" 
                        id="dia"
                        name="dia"
                        onChange={handleChange}
                         />
                      </div>
                    </div>
                  </div>

                  <div className="card-footer">
                    <button 
                    id="sos" 
                    className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
